<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">新能源发电解决方案</div>
        <div class="jieshao">
          公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务，包括电站项目开发、建设、交易、运维全过程交钥匙整体解决方案；标准化的电站评估交易体系；高效光伏组件；经验丰富的电站设计团队；先进的云计算监控管理平台；量身定制的运维方案，可显著提升电站综合发电效率，降低运维成本。
        </div>
        <div>
          <img
            style="width: 1186px; height: 416px; margin-top: 36px"
            src="./img/新能源1.png"
            alt=""
          />
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/新能源2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  height: 1404px;
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
</style>